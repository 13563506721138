import { NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import type { BiomeProjectId, CarbonSimulatorProjectId } from '@/shared/domain/Ids'

export const SimulationStatuses = Object.freeze({
  DRAFT: 'DRAFT',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  AWAITING_VERIFICATION: 'AWAITING_VERIFICATION',
  VERIFIED: 'VERIFIED',
  REJECTED: 'REJECTED',
  ARCHIVED: 'ARCHIVED',
})

export type ProjectsApiResponse = {
  results: {
    _id: CarbonSimulatorProjectId
    id: BiomeProjectId
    city: string
    country: string
    status: keyof typeof SimulationStatuses
  }[]
}

export type CarbonSimulatorScenarioApiResponse = {
  results: {
    /**
     * Contrairement à ce que le nom de ce champ suggère, totalCredits contient bien le total carbone capturé en tonne de CO2
     */
    totalCredits: number[]
  }
}

export default interface CarbonSimulatorGateway {
  getValidatedProjects(
    projectIds: BiomeProjectId[],
    accessToken: string,
  ): Promise<ProjectsApiResponse | NoResult>
  getScenario(
    carbonSimulationProjectId: CarbonSimulatorProjectId,
  ): Promise<CarbonSimulatorScenarioApiResponse | NoResult>
}
