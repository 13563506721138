import { FetchGateway, NoResult } from '@/shared/infrastructure/gateways/Fetch.gateway'
import {
  type CarbonSimulatorScenarioApiResponse,
  type ProjectsApiResponse,
  SimulationStatuses,
} from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type CarbonSimulatorGateway from '@/shared/infrastructure/gateways/CarbonSimulator.gateway'
import type { BiomeProjectId, CarbonSimulatorProjectId } from '@/shared/domain/Ids'

export default class CarbonSimulatorHttpGateway
  extends FetchGateway
  implements CarbonSimulatorGateway
{
  public baseUrl: string
  public constructor(_baseUrl: string) {
    super()
    this.baseUrl = _baseUrl
  }

  public async getScenario(
    carbonSimulationProjectId: CarbonSimulatorProjectId,
  ): Promise<CarbonSimulatorScenarioApiResponse | NoResult> {
    return this.fetch<CarbonSimulatorScenarioApiResponse>(
      '/projects/' + carbonSimulationProjectId + '/scenario',
      {
        method: 'GET',
      },
    )
  }

  public async getValidatedProjects(
    projectIds: BiomeProjectId[],
    accessToken: string,
  ): Promise<ProjectsApiResponse | NoResult> {
    const rawParams = {
      biomeId: projectIds.join(','),
      status: [SimulationStatuses.VERIFIED, SimulationStatuses.APPROVED].join(','),
    }
    const params = new URLSearchParams(rawParams)
    return this.fetch<ProjectsApiResponse>(`/projects?${params}`, { method: 'GET', accessToken })
  }
}
